import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import SEO from "src/components/seo.js";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 800;
  color: white;
  overflow: hidden;
  position: fixed;
`;

const Heading = styled.h1`
  font-family: "URWAccidalia";
  font-size: 30px;
  color: white;
  text-align: center;
  z-index: 805;
`;

const HomeButton = styled(Link)`
  font-family: "URWAccidalia";
  font-size: 20px;
  color: white;
  text-align: center;
  margin-top: 50px;
  z-index: 806;
  background: none;
  border: none;
  text-decoration: none;
  opacity: 1;
`;

const ErrorPage = () => {
  const browser = typeof window !== "undefined" && window;

  return (
    <>
      {browser && (
        <>
          <SEO title="HXOUSE · 404" />
          <Container id="ErrorContainer">
            <Heading>Page Not Found</Heading>
            <HomeButton to="/">Go Back to Home</HomeButton>
          </Container>
        </>
      )}
    </>
  );
};

export default ErrorPage;
